import { Injectable } from '@angular/core';
import { ToastController, AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertesService {
  public userid: any;
  public useralias: any;
  public usermail: any='';
  public usertoken: any;
  public userrol: any;
  public userapp: any;
  public usersusc: any;
  public userpremium: any;
  public gradaapp: any;
  public caducat: any;
  public actualizar: any=0;
  public userin: any = 0;
  // public path2 = 'http://localhost/api-football/api.php';
  // public pathleer2 = 'http://localhost/api-football/leer.php';
  // public pathmail = 'http://localhost/api-football/verifmail.php';
  // public pathpuntuar = 'http://localhost/api-football/puntuar.php';
  // public pathranking = 'http://localhost/api-football/ranking.php';
  // public pathfoto = 'https://manager.lagradaonline.com/assets/jugadors/';
  // public pathpremium = 'https://premiumimage.es/apimanager/leer.php';
  // public pathstripe = 'http://localhost/api-football/stripelg/checkout.php';
  // public pathstripe2 = 'http://localhost/api-football/stripe.php';
  // public pathapifut = 'https://premiumimage.es/tmanager2/api/api-football.php';
  //public path2 = 'https://tmanager.premiumimage.es/api/api.php';
  public path2 = 'https://tmanager2.premiumimage.es/api/api.php';
  public pathleer2 = 'https://tmanager2.premiumimage.es/api/leer.php';
  public pathmail = 'https://tmanager2.premiumimage.es/api/verifmail.php';
  public pathpuntuar = 'https://tmanager2.premiumimage.es/api/puntuarf.php';
  public pathranking = 'https://tmanager2.premiumimage.es/api/ranking.php';
  public pathfoto = 'https://tmanager2.premiumimage.es/assets/jugadors/';
  public pathpremium = 'https://premiumimage.es/apimanager/leer.php';
  public pathstripe = 'https://tmanager2.premiumimage.es/api/stripelg/checkout.php';
  public pathstripe2 = 'https://tmanager2.premiumimage.es/api/stripe.php';
  public pathapifut = 'https://tmanager2.premiumimage.es/api/api-football.php';
  public pathapi = 'https://tmanager2.premiumimage.es/api/';
  public apptoken = '';
  public appname = '';

  constructor(public toastCtrl: ToastController, public alertCtrl: AlertController) { }

  async presentToast(msg, color1) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 2000,
      position: 'middle',
      color: color1,
    });
    toast.present();
  }

  async presentAlert(msg2) {
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      subHeader: 'Subtitle',
      message: msg2,
      buttons: ['OK']
    });

    await alert.present();
  }
  async passwordPrompt(mail) {
    const alert = await this.alertCtrl.create({
      header: 'Cambiar contraseña',
      inputs: [
        {
          name: 'pass1',
          type: 'text',
          placeholder: 'Contraseña'
        },
        {
          name: 'pass2',
          type: 'text',
          placeholder: 'Repetir'
        },
        // multiline input.
        {
          name: 'mail',
          type: 'email',
          value: mail,
          placeholder: 'Mail'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'danger',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Cambiar',
          handler: (data: any) => {
            console.log('Confirm Ok');
            //enviar(data);
          }
        }
      ]
    });
    await alert.present();
  }
  async presentAlertMultipleButtons() {
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      subHeader: 'Subtitle',
      message: 'This is an alert message.',
      buttons: ['Cancel', 'Open Modal', 'Delete']
    });

    await alert.present();
  }

  async presentAlertConfirm() {
    const alert = await this.alertCtrl.create({
      header: 'Confirm!',
      message: 'Message <strong>text</strong>!!!',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Okay',
          handler: () => {
            console.log('Confirm Okay');
          }
        }
      ]
    });

    await alert.present();
  }

  async presentAlertPrompt() {
    const alert = await this.alertCtrl.create({
      header: 'Prompt!',
      inputs: [
        {
          name: 'name1',
          type: 'text',
          placeholder: 'Placeholder 1'
        },
        {
          name: 'name2',
          type: 'text',
          id: 'name2-id',
          value: 'hello',
          placeholder: 'Placeholder 2'
        },
        // multiline input.
        {
          name: 'paragraph',
          id: 'paragraph',
          type: 'textarea',
          placeholder: 'Placeholder 3'
        },
        {
          name: 'name3',
          value: 'http://ionicframework.com',
          type: 'url',
          placeholder: 'Favorite site ever'
        },
        // input date with min & max
        {
          name: 'name4',
          type: 'date',
          min: '2017-03-01',
          max: '2018-01-12'
        },
        // input date without min nor max
        {
          name: 'name5',
          type: 'date'
        },
        {
          name: 'name6',
          type: 'number',
          min: -5,
          max: 10
        },
        {
          name: 'name7',
          type: 'number'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: () => {
            console.log('Confirm Ok');
          }
        }
      ]
    });

    await alert.present();
  }
}
